interface GoogleTagManagerNoScriptProps {
  id: string;
}

// The script will be rendered on the server, but not hydrated on the client.
export const GoogleTagManagerNoScript = ({
  id,
}: GoogleTagManagerNoScriptProps) => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${id}`}
      height="0"
      width="0"
      style={{
        display: 'none',
        visibility: 'hidden',
      }}
      title="Google Tag Manager NoScript"
     />
  </noscript>
);
